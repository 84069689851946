<template>
	<!-- 投诉/建议 -->
	<div class="page_main">
		<!-- <van-nav-bar title="投诉/建议"
                 left-text="返回"
                 left-arrow
                 @click-left="onClickLeft" /> -->
		<van-form @submit="onSubmit">
			<div class="title_area fs_14">个人信息</div>
			<van-field v-model="formObj.contact" name="contact" label="联系人" placeholder="请输入联系人" />

			<van-field v-model="formObj.contactPhone" name="contactPhone" label="联系电话" :border="false" placeholder="请输入联系电话" />

			<div class="split_8 "></div>

			<van-field
				v-model="formObj.description"
				name="description"
				label="问题描述"
				rows="2"
				autosize
				type="textarea"
				maxlength="50"
				show-word-limit
				placeholder="请输入问题描述"
			/>

			<div class="split_8 "></div>

			<van-field name="uploader" label="上传照片 ">
				<template #input>
					<van-uploader :max-count="3" :after-read="afterRead" :before-delete="beforeDelete" v-model="formObj.photosAry" />
				</template>
			</van-field>

			<div style="margin: 16px;"><van-button block :disabled="isDisabled" :loading="isDisabled" type="info" color="#21AA61" native-type="submit">提交</van-button></div>
		</van-form>
	</div>
</template>
<script>
import { report } from '@/api/upload.js';

import { complaint } from '@/api/repairs.js';
import { Toast } from 'vant';
export default {
	props: {},
	components: {},
	data() {
		return {
			// form表单所有数据
			formObj: {
				contact: '', // 联系人
				contactPhone: '', // 联系电话
				description: '', // 问题描述
				photosAry: [], // 上传照片
				photoss: [] // 上传照片
			},
			columns: [],
			repairPartAry: [],
			showPicker: false,
			showPickerP: false,
			isDisabled: false
		};
	},
	created() {
		var user = localStorage.getItem('userInfo');
		if (user) {
			user = JSON.parse(user);
			this.formObj.contact = user.userName;
			this.formObj.contactPhone = user.phone;
		}
	},
	mounted() {},
	methods: {
		// 返回上一页
		onClickLeft() {
			history.back();
		},
		// 报修类型选择确定
		onConfirm(v) {
			this.formObj.repaire = v.text;
			this.formObj.repaireType = v.value;
			this.showPicker = false;
		},
		onConfirmP(v) {
			this.formObj.repair = v.text;
			this.formObj.repairPart = v.value;
			this.showPickerP = false;
		},
		// 上传图片
		afterRead(file) {
			file.status = 'uploading';
			file.message = '上传中...';
			// 此时可以自行将文件上传至服务器
			// console.log(file.file);
			report({ file: file.file, action: 'repair_apply' }).then(res => {
				// console.log(res)
				const data = res.data;
				console.log(data.statusCode);
				if (data.statusCode == 200) {
					this.formObj.photoss.push({
						url: data.data.fileName
					});
					console.log('123');
					file.status = 'done';
				} else {
					file.status = 'failed';
					file.message = '上传失败';
				}
			});
		},
		// 删除照片
		beforeDelete(a, b, c) {
			console.log(a, b, c);
			if (a.status == 'done') {
				this.formObj.photoss.splice(b.index, 1);
				return true;
			} else {
				return true;
			}
		},
		// 表单提交
		onSubmit() {
			console.log(this.formObj);
			const ary = [];
			this.formObj.photoss.forEach(item => {
				ary.push(item.url);
			});
			this.formObj.photos = ary.join(',');
			this.isDisabled = true;
			complaint(this.formObj)
				.then(res => {
					console.log(res);
					if (res.code == 200) {
						Toast.success('提交成功');
						setTimeout(() => {
							history.back();
						}, 1000);
					} else {
						Toast.fail(res.data.message);
					}
				})
				.finally(() => {
					this.isDisabled = false;
				});
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/ .van-field__label {
	color: #333333 !important;
	font-size: 16px;
}
.split_8 {
	background-color: #f7f8fa;
}
.page_main {
	background-color: #f7f8fa;
	height: 100%;
	min-height: 100vh;
}
.title_area {
	height: 34px;
	line-height: 34px;
	background-color: #f7f8fa;
	color: #999999;
	padding: 0 14px;
}
</style>
